import functions, { db } from 'firebase.js';
import { addDoc, collection, deleteDoc, doc, getCountFromServer, getDocs, increment, limit, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import React, { useContext } from 'react';
import { nanoid } from 'nanoid';

const FirebaseContext = React.createContext();

export function useFirebase() {
    return useContext(FirebaseContext);
}

export function FirebaseProvider({ children }) {

    const getUnbookedLessons = async () => {
        const unbookedLessons = [];
        const unbookedLessonsRef = query(collection(db, 'schedule'), where('booked', '==', false));
        const unbookedLessonsSnapshot = await getDocs(unbookedLessonsRef);
        unbookedLessonsSnapshot.forEach((doc) => {
            unbookedLessons.push(doc.data());
        });
        return unbookedLessons;
    }

    const getUnbookedLessonCount = async () => {
        const unbookedLessonsRef = query(collection(db, 'schedule'), where('booked', '==', false));
        const unbookedLessonsSnapshot = await getCountFromServer(unbookedLessonsRef);
        return unbookedLessonsSnapshot.data().count;
    }

    const getCapacity = async () => {
        const capacityRef = collection(db, 'capacity');
        const capacitySnapshot = await getDocs(capacityRef);
        const capacity = capacitySnapshot.docs.map(doc => doc.data());
        return capacity[0];
    }

    const getActivity = async () => {
        const activityQuery = query(collection(db, 'activity'), orderBy('date', 'desc'), limit(10));
        // Limit activity to 10 most recent entries
        const activitySnapshot = await getDocs(activityQuery);
        const activity = activitySnapshot.docs.map(doc => doc.data());
        return activity;
    }

    const getClientActivity = async (userId) => {
        const clientActivityQuery = query(collection(db, 'activity'), where('user', '==', userId), orderBy('created', 'desc'), limit(10));
        const clientActivitySnapshot = await getDocs(clientActivityQuery);
        const clientActivity = clientActivitySnapshot.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
        });
        return clientActivity;
    }

    const getChargesActivity = async () => {
        const chargesQuery = query(collection(db, 'activity'), where('type', '==', 'charge'));
        const chargesSnapshot = await getDocs(chargesQuery);
        const charges = chargesSnapshot.docs.map(doc => doc.data());
        console.log(charges);
        return charges;
    }

    const getSplashLocations = async () => {
        const splashLocationsQuery = query(collection(db, 'poolPartner'), where('active', '==', true));
        const splashLocationsSnapshot = await getDocs(splashLocationsQuery);
        const splashLocations = splashLocationsSnapshot.docs.map(doc => doc.data());
        return splashLocations;
    }

    const addLessonForClient = async (lessonDetails, user) => {
        console.log(lessonDetails);
        const lessonRef = collection(db, 'schedule');
        await addDoc(lessonRef, {
            booked: true,
            user: user.id,
            address: lessonDetails.address,
            startTime: new Date(lessonDetails.startTime),
            endTime: new Date(lessonDetails.endTime),
            instructor: lessonDetails.instructor,
            instructorId: lessonDetails.instructorId,
            onHold: false,
        }).then(() => {
            return true;
        }).catch(err => {
            console.log(err);
            return false;
        });
    }

    const cancelLesson = async (lesson, returnToClient, reason) => {
        const lessonRef = doc(db, 'schedule', lesson.id);
        await updateDoc(lessonRef, {
            booked: false,
            user: '',
            address: '',
            onHold: false,
        }).then(() => {
            if (returnToClient) {
                const clientRef = doc(db, 'users', lesson.user);
                updateDoc(clientRef, {
                    ClassesAvailable: increment(1),
                }).catch(err => {
                    console.log(err);
                });
            }
            // Add activity to log
            const activityRef = collection(db, 'activity');
            addDoc(activityRef, {
                type: 'cancel',
                user: lesson.user,
                lessonId: lesson.id,
                created: new Date(),
                reason: reason,
                lessonReturned: returnToClient,
            }).catch(err => {
                console.log(err);
            });
            return 'success';
        }).catch(err => {
            console.log(err);
            return err;
        });
    }

    const deleteLesson = async (lesson) => {
        const lessonRef = doc(db, 'schedule', lesson.id);
        if (lesson.booked || lesson.onHold) {
            const clientRef = doc(db, 'users', lesson.user);
            updateDoc(clientRef, {
                ClassesAvailable: increment(1),
            }).catch(err => {
                console.log(err);
            });
        }
        await deleteDoc(lessonRef).then(() => {
            return 'success';
        }).catch(err => {
            console.log(err);
            return err;
        });
    }

    const getTeamData = async () => {
        const teamRef = collection(db, 'splashTeam');
        const teamSnapshot = await getDocs(teamRef);
        const team = teamSnapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id };
        });
        return team;
    }

    const updateTeamMember = async (teamMember) => {
        const teamRef = doc(db, 'splashTeam', teamMember.id);
        await updateDoc(teamRef, {
            [teamMember.field]: teamMember.value,
        }).then(() => {
            return 'success';
        }).catch(err => {
            console.log(err);
            return err;
        });
    }

    const getTeamsHours = async (payPeriodStart, payPeriodEnd) => {
        // Get all of the booked lessons on the schedule from the pay period
        const lessonsRef = query(collection(db, 'schedule'), where('booked', '==', true), where('startTime', '>=', new Date(payPeriodStart)), where('startTime', '<=', new Date(payPeriodEnd)));
        const lessonsSnapshot = await getDocs(lessonsRef);
        const lessons = lessonsSnapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id };
        }
        );
        // Get all of the team members
        const teamRef = collection(db, 'splashTeam');
        const teamSnapshot = await getDocs(teamRef);
        const team = teamSnapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id };
        }
        );
        // Loop through the team members and get their hours
        const teamHours = [];
        team.forEach(teamMember => {
            const teamMemberHours = lessons.filter(lesson => lesson.instructor === teamMember.firstName);
            teamHours.push({ ...teamMember, hours: teamMemberHours.length });
        }
        );
        return teamHours;
    }

    const getClientsWithRemainingLessons = async () => {
        const clientsRef = query(collection(db, 'users'), where('ClassesAvailable', '>', 0));
        const clientsSnapshot = await getDocs(clientsRef);
        const clients = clientsSnapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id };
        });
        return clients;
    }

    const updateClient = async (client) => {
        const clientRef = doc(db, 'users', client.id);
        await updateDoc(clientRef, {
            [client.field]: client.value,
        }).then(() => {
            return 'success';
        }).catch(err => {
            console.log(err);
            return err;
        });
    }

    const getPoolPartnerHours = async (payPeriodStart, payPeriodEnd, partnerAddress) => {
        // Get all of the booked lessons on the schedule from the pay period
        const lessonsRef = query(collection(db, 'schedule'), where('address', '==', partnerAddress), where('startTime', '>=', new Date(payPeriodStart)), where('startTime', '<=', new Date(payPeriodEnd)));
        const lessonsSnapshot = await getDocs(lessonsRef);
        const lessons = lessonsSnapshot.docs.map(doc => {
            // convert Timestamp to Date
            const lessonData = doc.data();
            return { ...lessonData, id: doc.id, startTime: lessonData.startTime.toDate() };
        });
        // If the lessons take place at the same time remove one of them
        const uniqueLessons = [];
        lessons.forEach(lesson => {
            const lessonExists = uniqueLessons.find(uniqueLesson => uniqueLesson.startTime === lesson.startTime);
            if (!lessonExists) {
                uniqueLessons.push(lesson);
            }
        });
        return lessons;
    }

    const addNewSession = async (session) => {
        const sessionRef = collection(db, 'sessions');
        // convert offDates to firebase timestamps
        session.offDates = session.offDates.map(offDate => new Date(offDate));
        // convert pricePerLesson to cents
        await addDoc(sessionRef, {
            name: session.sessionName,
            startDate: new Date(session.startDate),
            endDate: new Date(session.endDate),
            registrationStart: new Date(session.registrationStart),
            pricePerLesson: session.pricePerLesson * 100,
            lessonsPerWeek: session.lessonsPerWeek,
            lessonDuration: session.lessonDuration,
            offDates: session.offDates,
            status: session.status,
            description: session.sessionDescription,
            image: session.sessionImage,
        }).then(() => {
            return true;
        }).catch(err => {
            console.log(err);
            return false;
        });
    }

    const getSessions = async () => {
        const sessionsRef = collection(db, 'sessions');
        const sessionsSnapshot = await getDocs(sessionsRef);
        const sessions = sessionsSnapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id };
        });
        return sessions;
    }

    // Get the swimmers on a user's account
    const getSwimmers = async (user) => {
        const swimmersRef = query(collection(db, 'swimmers'), where('user', '==', user.id));
        const swimmersSnapshot = await getDocs(swimmersRef);
        const swimmers = swimmersSnapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id };
        });
        return swimmers;
    }

    const addLessonType = async (lessonType) => {
        const lessonTypeRef = collection(db, 'lessonTypes');
        await addDoc(lessonTypeRef, {
            name: lessonType.name,
            maxParticipants: lessonType.maxParticipants,
            pricePerLesson: lessonType.pricePerLesson,
            duration: lessonType.duration,
            typeId: "type_" + nanoid(6),
        }).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
            return err;
        });
    }

    const getLessonTypes = async () => {
        const lessonTypesRef = collection(db, 'lessonTypes');
        const lessonTypesSnapshot = await getDocs(lessonTypesRef);
        const lessonTypes = lessonTypesSnapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id };
        });
        return lessonTypes;
    }

    const addInstructorSchedule = async (schedule) => {
        const scheduleRef = collection(db, 'instructorSchedule');
        await addDoc(scheduleRef, {
            instructorId: schedule.instructorId,
            days: schedule.days,
            offDates: schedule.offDates,
            sessionId: schedule.sessionId,
        }).then(() => {
            return true;
        }).catch(err => {
            console.log(err);
            return false;
        });
    }


    const value = {
        getUnbookedLessons,
        getCapacity,
        getUnbookedLessonCount,
        getActivity,
        getClientActivity,
        getChargesActivity,
        getSplashLocations,
        addLessonForClient,
        cancelLesson,
        deleteLesson,
        getTeamData,
        updateTeamMember,
        getTeamsHours,
        getClientsWithRemainingLessons,
        updateClient,
        getPoolPartnerHours,
        addNewSession,
        getSessions,
        getSwimmers,
        addLessonType,
        getLessonTypes,
        addInstructorSchedule,
    }

    return (
        <FirebaseContext.Provider value={value}>
            {children}
        </FirebaseContext.Provider>
    )
}